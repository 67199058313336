<template>
    <zw-sidebar @shown="shown" :title="$t('settings.news_template.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.subject"
                                        name="subject"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.link"
                                        name="link"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'body') }}</label>
                        <div class="mb-3">
                            <b-badge v-for="(placeholder, index) in getNewsPlaceholders()"
                                     v-bind:key="index"
                                     variant="info"
                                     class="mr-2"
                                     @click="insertPlaceholder(placeholder)"
                            >{{ placeholder }}
                            </b-badge>
                        </div>
                        <zw-ckeditor ref="zwckeditor" v-model="form.body"></zw-ckeditor>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'NewsTemplateModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                subject: '',
                body: '',
            },
            form: {},
            labelPrefix: 'settings.news_template.label.',
        }
    },
    methods: {
        ...mapGetters('NewsTemplate', ['getNewsTemplate', 'getNewsPlaceholders']),
        insertPlaceholder(placeholder) {
            this.$refs.zwckeditor.$refs.ckeditor.instance.insertText('{{' + placeholder + '}}')
        },
        shown() {
            const store = this.$store.dispatch('NewsTemplate/fetchTemplate', this.payload.id)

            Promise.all([store])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getNewsTemplate()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('NewsTemplate/saveTemplate', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>